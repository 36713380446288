import React from "react"
import IndexPage from './index.js'

const ElectionsOnlineVotingSoftwarePage = () => (
  <IndexPage
    title="Create Elections with nVotes' Online Voting Software"
    description="Grant legitimacy to your election with our auditable online voting software"
    showCallToAction={true}
  />
)

export default ElectionsOnlineVotingSoftwarePage
